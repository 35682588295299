import {computed} from "vue"
import {useStore} from "vuex"

const useProyectos = () => {
  const store = useStore()

  const getProyectoInfo = async (proyectoId) => {
    return await store.dispatch("proyectos/getProyectoInfo", proyectoId)
  }

  const getProyectoCatalogos = async () => {
    return await store.dispatch("proyectos/getProyectoCatalogos")
  }

  const setGuardarProyecto = async (proyecto) => {
    return await store.dispatch("proyectos/setGuardarProyecto", proyecto)
  }

  const getProyectos = async (busqueda) => {
    return await store.dispatch("proyectos/getProyectos", busqueda)
  }

  const setEliminarProyecto = async (proyecto) => {
    return await store.dispatch("proyectos/setEliminarProyecto", proyecto)
  }

  const formProyectoIni = {
    id: null,
    nombre: null,
    cliente_id: null,
    elementos: null,
    turno_horario_id: null,
    turno_dia_id: null,
    hora_entrada: null,
    cambio_turno: null,
    hora_salida: null,
    dias_requeridos: [],
    direccion: null,
    consignas: null,
    actividades: null,
    equipo_asignado: null,
    equipo_industrial: null,
    uniforme_id: null,
    requiere_lluvia: null,
    contacto_oper_id: null,
    contacto_admin_id: null,
    empleados: [],
    bajas: [],
  }

  const dias = [
    {dia: 1, label: "Lunes"},
    {dia: 2, label: "Martes"},
    {dia: 3, label: "Miercoles"},
    {dia: 4, label: "Jueves"},
    {dia: 5, label: "Viernes"},
    {dia: 6, label: "Sabado"},
    {dia: 7, label: "Domingo"},
  ]

  const formaQuitarIni = {
    proyecto_id: null,
    empleado: null,
    empleado_id: null,
    fecha_baja: null,
    motivo_id: null,
    comentario: null,
    renuncio: null,
  }

  const busquedaIni = {
    filtro: "",
  }

  return {
    dias,
    formProyectoIni,
    formaQuitarIni,
    busquedaIni,
    getProyectoInfo,
    getProyectoCatalogos,
    setGuardarProyecto,
    getProyectos,
    setEliminarProyecto,
    turnos: computed(() => store.getters["proyectos/turnos"]),
    proyectos: computed(() => store.getters["proyectos/proyectos"]),
    horarios: computed(() => store.getters["proyectos/horarios"]),
    uniformes: computed(() => store.getters["proyectos/uniformes"]),
    clientes: computed(() => store.getters["proyectos/clientes"]),
    contactos: computed(() => store.getters["proyectos/contactos"]),
    disponibles: computed(() => store.getters["proyectos/disponibles"]),
    motivos: computed(() => store.getters["proyectos/motivos"]),
    loading: computed(() => store.getters["proyectos/loading"]),
    busqueda: computed(() => store.getters["proyectos/busqueda"]),
  }
}

export default useProyectos
