<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-sm-stretch">
          <h3 class="card-title">Captura</h3>
        </div>
        <div class="card-body">
          <div class="row mt-3">
            <div class="col-md-8">
              <div class="card card-bordered mt-3">
                <div class="card-header">
                  <div class="card-title-form">Datos Generales</div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control required"
                          id="nombre"
                          v-model="forma.nombre"
                        />
                        <label for="nombre">Nombre del proyecto</label>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select required"
                          id="turno_horario_id"
                          v-model="forma.turno_horario_id"
                        >
                          <option
                            v-for="turno in turnos"
                            :key="turno.id"
                            :value="turno.id"
                          >
                            {{ turno.turno }}
                          </option>
                        </select>
                        <label for="turno_horario_id">Duracion de Turnos</label>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select"
                          id="turno_dia_id"
                          v-model="forma.turno_dia_id"
                        >
                          <option
                            v-for="horario in horarios"
                            :key="horario.id"
                            :value="horario.id"
                          >
                            {{ horario.horario }}
                          </option>
                        </select>
                        <label for="turno_dia_id">Horario Turno</label>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control required"
                          id="elementos"
                          v-model="forma.elementos"
                        />
                        <label for="elementos">Elementos solicitados</label>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="hora_entrada"
                          v-model="forma.hora_entrada"
                        />
                        <label for="hora_entrada">Hora de entrada</label>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="cambio_turno"
                          v-model="forma.cambio_turno"
                        />
                        <label for="cambio_turno">Cambio de turno</label>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="hora_salida"
                          v-model="forma.hora_salida"
                        />
                        <label for="hora_salida">Hora de salida</label>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="rounded border p-3">
                        <span class="dias-title">Dias requeridos</span>

                        <div
                          class="
                            form-check form-check-custom form-check-solid
                            me-8
                            mt-2
                          "
                        >
                          <template v-for="dia in dias" :key="dia.dia">
                            <input
                              class="form-check-input h-15px w-15px"
                              type="checkbox"
                              :value="dia.dia"
                              v-model="forma.dias_requeridos"
                              v-bind:id="`dia${dia.dia}`"
                            />
                            <label
                              class="form-check-label mx-2"
                              v-bind:for="`dia${dia.dia}`"
                            >
                              {{ dia.label }}
                            </label>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-3">
                      <div class="form-floating mb-3">
                        <textarea
                          class="form-control"
                          id="direccion"
                          rows="15"
                          v-model="forma.direccion"
                          :style="{ height: '80px' }"
                        />
                        <label for="direccion">Dirección del punto</label>
                      </div>
                    </div>
                    <div class="col-md-6 mt-3">
                      <div class="form-floating mb-3">
                        <textarea
                          class="form-control"
                          id="consignas"
                          rows="15"
                          v-model="forma.consignas"
                          :style="{ height: '80px' }"
                        />
                        <label for="consignas">Consignas</label>
                      </div>
                    </div>
                    <div class="col-md-6 mt-3">
                      <div class="form-floating mb-3">
                        <textarea
                          class="form-control"
                          id="actividades"
                          rows="15"
                          v-model="forma.actividades"
                          :style="{ height: '80px' }"
                        />
                        <label for="actividades">Actividades</label>
                      </div>
                    </div>
                    <div class="col-md-6 mt-3">
                      <div class="form-floating mb-3">
                        <textarea
                          class="form-control"
                          id="equipo_asignado"
                          rows="15"
                          v-model="forma.equipo_asignado"
                          :style="{ height: '80px' }"
                        />
                        <label for="equipo_asignado">Equipo asignado</label>
                      </div>
                    </div>
                    <div class="col-md-6 mt-3">
                      <div class="form-floating mb-3">
                        <textarea
                          class="form-control"
                          id="equipo_industrial"
                          rows="15"
                          v-model="forma.equipo_industrial"
                          :style="{ height: '80px' }"
                        />
                        <label for="equipo_industrial">Equipo industrial</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select"
                          id="uniforme_id"
                          v-model="forma.uniforme_id"
                        >
                          <option
                            v-for="uniforme in uniformes"
                            :key="uniforme.id"
                            :value="uniforme.id"
                          >
                            {{ uniforme.uniforme }}
                          </option>
                        </select>
                        <label for="uniforme_id">Tipo de uniforme</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select"
                          id="requiere_lluvia"
                          v-model="forma.requiere_lluvia"
                        >
                          <option value="1">Si</option>
                          <option value="0">No</option>
                        </select>
                        <label for="requiere_lluvia"
                          >Requiere equipo para lluvia</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-bordered mt-3">
                <div class="card-header">
                  <div class="card-title-form">Empleados Asignados</div>
                  <div class="card-toolbar">
                    <button
                      type="button"
                      class="btn btn-sm btn-light-dark"
                      @click="handleAgregarEmpleado"
                    >
                      Agregar
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div
                          class="col-md-6 col-xl-6 col-xxl-6"
                          v-for="empleado in forma.empleados"
                          :key="empleado.id"
                        >
                          <div class="card card-bordered">
                            <div
                              class="
                                card-body
                                d-flex
                                flex-center flex-column
                                pt-12
                                p-9
                              "
                            >
                              <div
                                class="symbol symbol-65px symbol-circle mb-5"
                              >
                                <img :src="empleado.foto" alt="image" />
                              </div>

                              <a
                                href="#"
                                class="
                                  fs-4
                                  text-gray-800 text-hover-primary
                                  fw-bold
                                  mb-0
                                "
                                >{{
                                  empleado.nombre + " " + empleado.paterno
                                }}</a
                              >

                              <div class="fw-semibold text-gray-400 mb-6">
                                Edad: {{ empleado.edad }} <br />
                                Telefono:
                                {{ empleado.telefono }}
                              </div>

                              <div class="d-flex flex-center flex-wrap">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-light-danger"
                                  @click="
                                    () => {
                                      handleEliminar(empleado);
                                    }
                                  "
                                >
                                  <i class="bi bi-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-8">
              <div class="card card-bordered mt-3">
                <div class="card-header">
                  <div class="card-title-form">Datos del cliente</div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select required"
                          id="cliente_id"
                          v-model="forma.cliente_id"
                        >
                          <option
                            v-for="cliente in clientes"
                            :key="cliente.id"
                            :value="cliente.id"
                          >
                            {{ cliente.nombre }}
                          </option>
                        </select>
                        <label for="cliente_id">Cliente</label>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select required"
                          id="contacto_oper_id"
                          v-model="forma.contacto_oper_id"
                        >
                          <option
                            v-for="op in operativos"
                            :key="op.id"
                            :value="op.id"
                          >
                            {{ op.nombre }}
                          </option>
                        </select>
                        <label for="contacto_oper_id">Contacto Operativo</label>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-floating mb-3">
                        <select
                          class="form-select required"
                          id="contacto_admin_id"
                          v-model="forma.contacto_admin_id"
                        >
                          <option
                            v-for="op in administrativos"
                            :key="op.id"
                            :value="op.id"
                          >
                            {{ op.nombre }}
                          </option>
                        </select>
                        <label for="contacto_admin_id"
                          >Contacto Administrativo</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="handleGuardar"
          >
            GUARDAR
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="modalDisponibles">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Empleados disponibles</h3>

          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-1"></span>
          </div>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-sm table-bordered g-2">
                <thead>
                  <tr class="border table-active">
                    <th class="text-center"></th>
                    <th>Nombre</th>
                    <th>Edad</th>
                    <th>Telefono</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border" v-for="item in disponibles" :key="item.id">
                    <td class="text-center">
                      <img
                        :src="item.foto"
                        :alt="`${item.nombre} ${item.paterno} ${item.materno}`"
                        class="h-100px"
                      />
                    </td>
                    <td>
                      {{ `${item.nombre} ${item.paterno} ${item.materno}` }}
                    </td>
                    <td>{{ item.edad }}</td>
                    <td>{{ item.telefono }}</td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-sm btn-xs btn-light-primary mx-2"
                        @click="
                          () => {
                            handleAgregar(item);
                          }
                        "
                      >
                        <i class="bi bi-person-add"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="border"></tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light btn-sm"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="modalBaja">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Baja de empleado del proyecto</h3>

          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-1"></span>
          </div>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <input
                  type="date"
                  class="form-control"
                  id="fecha_baja"
                  v-model="formaBaja.fecha_baja"
                />
                <label for="nombre">Fecha de Baja</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-floating mb-3">
                <select
                  class="form-select"
                  id="motivo_id"
                  v-model="formaBaja.motivo_id"
                >
                  <option v-for="op in motivos" :key="op.id" :value="op.id">
                    {{ op.motivo }}
                  </option>
                </select>
                <label for="motivo_id">Motivo</label>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-floating mb-3">
                <textarea
                  class="form-control"
                  id="comentario"
                  v-model="formaBaja.comentario"
                />
                <label for="comentario">Comentario</label>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-floating mb-3">
                <select
                  class="form-select"
                  id="motivo_id"
                  v-model="formaBaja.renuncio"
                >
                  <option value="1">Si</option>
                  <option value="0">No</option>
                </select>
                <label for="renuncio">Presento reuncia</label>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light btn-sm"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-light-danger btn-sm"
            @click="handleQuitarEmpleado"
          >
            Quitar empleado
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useProyectos from "../composables/useProyectos";
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
export default {
  props: ["cid"],
  setup(props) {
    const {
      busqueda,
      formProyectoIni,
      dias,
      getProyectoInfo,
      getProyectoCatalogos,
      setGuardarProyecto,
      getProyectos,
      turnos,
      horarios,
      uniformes,
      clientes,
      contactos,
      disponibles,
      formaQuitarIni,
      motivos,
    } = useProyectos();
    const forma = ref(formProyectoIni);
    const operativos = ref([]);
    const administrativos = ref([]);
    const firstTime = ref(true);
    const formaBaja = ref(formaQuitarIni);
    const router = useRouter();

    onMounted(async () => {
      await getProyectoCatalogos();
    });

    if (props.cid && props.cid.length > 0) {
      getProyectoInfo(props.cid).then((result) => {
        if (result.ok) {
          forma.value = { ...result.info, bajas: [] };
        }
      });
    }

    const handleAgregarEmpleado = () => {
      $("#modalDisponibles").modal("show");
    };

    const handleAgregar = (empleado) => {
      const idx = forma.value.empleados.find((item) => item.id === empleado.id);
      if (!idx) {
        forma.value.empleados.push(empleado);
        $("#modalDisponibles").modal("hide");
      }
    };

    const handleGuardar = () => {
      Swal.fire({
        title: "Confirmar operación",
        text: "¿Desea guardar el proyecto?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Guardar!",
      }).then((result) => {
        if (result.isConfirmed) {
          setGuardarProyecto({ ...forma.value }).then((result) => {
            if (result.ok) {
              Swal.fire("Guardado", "Se ha guardado correctamente", "success");
              getProyectos(busqueda.value);
              return router.replace({ name: "proyectos" });
            } else {
              Swal.fire("Error", result.message, "error");
            }
          });
        }
      });
    };

    const handleEliminar = (empleado) => {
      formaBaja.value = { ...formaQuitarIni };
      formaBaja.value.proyecto_id = forma.value.id;
      formaBaja.value.empleado_id = empleado.id;
      formaBaja.value.empleado = empleado;
      $("#modalBaja").modal("show");
    };

    const handleQuitarEmpleado = () => {
      const idx = forma.value.bajas.find(
        (item) => item.empleado_id === formaBaja.value.empleado_id
      );
      if (!idx) {
        forma.value.bajas.push(formaBaja.value);
        forma.value.empleados = forma.value.empleados.filter(
          (item) => item.id !== formaBaja.value.empleado_id
        );
      }

      $("#modalBaja").modal("hide");
    };

    watch(
      () => forma.value.cliente_id,
      () => {
        if (!firstTime.value) {
          forma.value.contacto_oper_id = null;
          forma.value.contacto_admin_id = null;
        }

        operativos.value = [
          ...contactos.value.filter(
            (item) =>
              item.cliente_id === forma.value.cliente_id && item.tipo == 2
          ),
        ];
        administrativos.value = [
          ...contactos.value.filter(
            (item) =>
              item.cliente_id === forma.value.cliente_id && item.tipo == 1
          ),
        ];

        firstTime.value = false;
      }
    );

    return {
      forma,
      dias,
      turnos,
      horarios,
      uniformes,
      clientes,
      contactos,
      operativos,
      administrativos,
      disponibles,
      handleGuardar,
      handleAgregarEmpleado,
      handleAgregar,
      formaBaja,
      handleEliminar,
      handleQuitarEmpleado,
      motivos,
    };
  },
};
</script>

<style>
.dias-title {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  font-size: 11px;
}
</style>